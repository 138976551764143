function capitalizeFirstLetter(string) {
  return string
    .charAt(0)
    .toUpperCase() + string.slice(1);
}

export function getUserFirstName(displayName) {
  let userFirstName = displayName
    .substring(0, displayName.indexOf(" "))
  return capitalizeFirstLetter(userFirstName)
}

export function getUserLastName(displayName) {
  let userLastName = displayName
    .substring(displayName.indexOf(" "))
  return capitalizeFirstLetter(userLastName)
}
