import React, {useEffect} from "react"
// import Grid from '@material-ui/core/Grid';
// import Typography from '@material-ui/core/Typography';
// import {makeStyles} from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
// import Grow from '@material-ui/core/Grow';
import WebFont from 'webfontloader';
import {auth, firestore} from '../utils/firebase.js';
// import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
// import firebase from 'firebase/app';
// import "firebase/auth";
import Loader from '../components/WidgetComponents/Loader'
import {navigate} from 'gatsby'
import SEO from "../components/seo.js"
import Layout from "../components/layout"
import {getUserFirstName, getUserLastName} from '../utils/general';

// import signInButton from "../../images/signin.png"

// const useStyles = makeStyles(theme => ({
//   signInButton: {
//     width: '200px',
//     padding: '0px',
//     margin: '0px',
//      boxShadow: '0 5px 14px 6px rgba(0,0,0,0.1)',
//     filter: 'drop-shadow(0px 3px 8px rgba(0,0,0,0.35))',
//     transition: 'all 0.5s cubic-bezier(.25,.8,.25,1)',
//     '&:hover': {
//       filter: 'drop-shadow(0px 3px 4px rgba(0,0,0,0.2)) brightness(90%)',
//
//        boxShadow: '0 5px 14px 6px rgba(0,0,0,0.1)',
//        '-webkit-filter': 'drop-shadow(5px 5px 5px #222)',
//        filter: 'drop-shadow(0px 3px 5px rgba(0,0,0,0.4))',
//        filter: 'brightness(90%)',
//       cursor: 'pointer'
//     }
//   }
// }));

// let fontsToLoad = [
//   'Work Sans',
//   'Lobster',
//   'Dancing Script',
//   'Raleway',
//   'Poppins',
//   'Playfair Display',
//   'Bebas Neue',
//   'Bangers',
//   'Comfortaa',
//   'Abril Fatface',
//   'Righteous',
//   'Fredoka One',
//   'Pacifico',
//   'Courgette'
// ]

if (typeof window !== `undefined`) {
  // getFirebase()
  WebFont.load({
    google: {
      families: 'Work Sans:lighter,normal,bold,bolder'
    }
  });
}

// const auth = firebase.auth;

// function signInWithGoogle() {
//   const provider = new auth.GoogleAuthProvider();
//
//   const state = {
//     'redirected': true
//   }
//   const title = ''
//   const url = '/app/waitlist'
//   if (typeof window !== `undefined`) {
//     window
//       .history
//       .pushState(state, title, url)
//   }
//
//   return auth().signInWithRedirect(provider);
// }

// async function googleSignIn() {
//   try {
//     await signInWithGoogle();
//   } catch (error) {
//     console.log('Error')
//      this.setState({error: error.message});
//   }
// }

function SignIn(props) {
  // const [loaded, setLoaded] = useState(false)
  // const [activeUser, setActiveUser] = useState(null)
  // const [isSignedIn, setIsSignedIn] = useState(null)

  // const classes = useStyles();

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        let docRef = firestore
          .collection("users")
          .doc(user.uid);
        docRef
          .get()
          .then(function(doc) {
            if (doc.exists) {
              window
                .analytics
                .track("Signed In", {
                  // userId: user.uid,
                  // "type": "organic",
                  "first_name": getUserFirstName(user.displayName),
                  "last_name": getUserLastName(user.displayName),
                  "email": user.email,
                  // "phone": "410-555-9412",
                  // "username": "pgibbons",
                  // "title": "Mr",
                });
              if (doc.data()['betaAccess'] == true) {
                navigate('/my/dashboard')
              } else if (doc.data()['requestedAccess'] == true) {
                navigate('/requested')
              } else {
                navigate('/join/about')
              }
            } else {
              firestore
                .collection("users")
                .doc(user.uid)
                .set({
                  uid: user.uid,
                  email: user.email,
                  first: getUserFirstName(user.displayName),
                  last: getUserLastName(user.displayName),
                  photoURL: user.photoURL,
                  joinDate: Date(),
                  brands: [],
                  betaAccess: false,
                  requestedAccess: false
                });
              window
                .analytics
                .identify(user.uid, {
                  name: user.displayName,
                  first_name: getUserFirstName(user.displayName),
                  last_name: getUserLastName(user.displayName),
                  email: user.email,
                  beta_access: false,
                  requested_access: false
                });
              window
                .analytics
                .track("Signed Up", {
                  // userId: user.uid,
                  // "type": "organic",
                  "first_name": getUserFirstName(user.displayName),
                  "last_name": getUserLastName(user.displayName),
                  "email": user.email,
                  // "phone": "410-555-9412",
                  // "username": "pgibbons",
                  // "title": "Mr",
                });
              navigate('/join/about')
            }
          })
          .catch(function(error) {
            console.log("Error getting document:", error);
          });
        // console.log(user)
        // setActiveUser(user);
      } else {
        // localStorage.setItem("onSignIn", "true");
        console.log('Nope')
        navigate('/')
      }
    })
  })

  // const uiConfig = {
  //    Display Google as auth provider.
  //   signInFlow: 'redirect',
  //    signInSuccessUrl: '/app/waitlist',
  //   signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
  //   callbacks: {
  //      uiShown: function() {
  //         The widget is rendered.
  //         Hide the loader.
  //        alert('yeet')
  //      }
  //       Avoid redirects after sign-in.
  //     signInSuccessWithAuthResult: () => false
  //   }
  // };

  // const page = (<Grow in="in">
  //   <Grid container="container" direction="column" justify="center" alignItems="center" style={{
  //       backgroundColor: '',
  //       height: '100vh',
  //       width: '100vw',
  //       padding: '0px 5vw'
  //     }}>
  //     <Typography style={{
  //         fontFamily: 'Lobster',
  //         fontWeight: 'bold',
  //         fontSize: '35px',
  //         paddingBottom: '30px',
  //         textAlign: 'center',
  //         lineHeight: 1.2
  //       }}>{
  //         activeUser
  //           ? activeUser.displayName
  //           : 'QuickStyle'
  //       }</Typography>
  //     <Typography style={{
  //         fontFamily: 'Work Sans',
  //         fontWeight: 'normal',
  //         fontSize: '25px',
  //         paddingBottom: '30px',
  //         textAlign: 'center',
  //         lineHeight: 1.2
  //       }}>{
  //         activeUser
  //           ? activeUser.email
  //           : 'QuickStyle'
  //       }</Typography>
  //     <a onClick={() => navigate('/app/signin')} style={{
  //         padding: '30px'
  //       }}>Back</a>
  //   </Grid>
  // </Grow>)

  return (<Layout><SEO title='Authenticating'/><Loader/></Layout>)

  // return (<div>
  //   <h1>My App</h1>
  //   <p>Welcome {
  //       firebase
  //         .auth()
  //         .currentUser
  //         .displayName
  //     }! You are now signed-in!</p>
  //   <a onClick={() => firebase.auth().signOut()}>Sign-out</a>
  // </div>)
  // return <Loader/>
}

export default SignIn

// <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
